import React, { FunctionComponent, Fragment, ReactNode } from "react";
import { SEO } from "./Seo";
import { Footer } from "./Footer";
import { LinkedIn } from "./social/LinkedIn";

type PageProps = {
  pageTitle: string;
  children: ReactNode
};

const _Page: FunctionComponent<PageProps> = ({ children, pageTitle }) => {
  return (
    <Fragment>
      <SEO title={pageTitle} />
      {children}
      <Footer>
        <LinkedIn/>
      </Footer>
    </Fragment>
  );
};

export const Page = _Page;