import React, { FunctionComponent, ReactNode } from "react";

type FooterProps = {
  children: ReactNode;
};

const _Footer: FunctionComponent<FooterProps> = ({children}) => {

  return (
    <div className="sdv-footer">
      {children}
    </div>
  );
};

export const Footer = _Footer;