import { createElement, FunctionComponent } from "react";


export enum TypographyElementTypes {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  P = 'p',
  SPAN = 'span'
};

export enum TypographyVariants {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  SUB1 = 'sub1',
  SUB2 = 'sub2',
  BODY1 = 'body1',
  BODY2 = 'body2',
  BUTTON = 'button'
};

const TYPOGRAPHY_VARIANT_MAP = {
  [TypographyVariants.H1]: TypographyElementTypes.H1,
  [TypographyVariants.H2]: TypographyElementTypes.H2,
  [TypographyVariants.H3]: TypographyElementTypes.H3,
  [TypographyVariants.H4]: TypographyElementTypes.H4,
  [TypographyVariants.SUB1]: TypographyElementTypes.H5,
  [TypographyVariants.SUB2]: TypographyElementTypes.H5,
  [TypographyVariants.BODY1]: TypographyElementTypes.P,
  [TypographyVariants.BODY2]: TypographyElementTypes.P,
  [TypographyVariants.BUTTON]: TypographyElementTypes.SPAN,
};

type TypographyProps = {
  variant: TypographyVariants;
  elementType?: TypographyElementTypes;
};

const _Typography: FunctionComponent<TypographyProps> = ({variant, elementType, children}) => {
  const getElementType = () => {
    if (elementType) return elementType;

    return TYPOGRAPHY_VARIANT_MAP[variant];
  };

  return createElement(
    getElementType(), 
    {
      className: `sdv-typography sdv-typography--${variant}`
    }, 
    children
  );
};

export const Typography = _Typography;